import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const DonacijePage = () => (
  <Layout>
    <div id="wrapper">
        <ul>
            <li><Link to="/" className="button ">Nazad</Link></li>
        </ul>
        <article
          id="donacije"
          className="donacije"
        >
          <h2 className="major">Donacije</h2>
          <p>
            Niste ljubitelj sporta, ili niste u mogućnosti da prijavite ekipu, a
            želite da pomognete?
          </p>
          <p>
            Ispod se nalaze detalji kako možete da donirate i pomognete i
            prikupljanju sredstava za sređivanje stacionara u Lipovici.{' '}
          </p>
          <p>
            Za uplate putem e-bankinga: <br />
            • Zarad transparentnosti molimo Vas da unesete svoje ime i prezime /
            ime firme prilikom uplaćivanja
            <br />
            • Obavezno popuniti polje "poziv na broj"
            <br />
            • Svrha uplate: Humanitarni turnir
            <br />
            • Primalac: PHP Srbija, Šeširdžijska 1, 11000 Beograd
            <br />
            • Šifra plaćanja: 287
            <br />
            • Valuta: RSD
            <br />
            • Iznos: xxxxx,xx RSD
            <br />
            • Račun primaoca: 160-383598-90
            <br />• Poziv na broj: humanitarni turnir{' '}
          </p>
          <p>
            Za uplate u pošti: <br />
            • Zarad transparentnosti molimo Vas da unesete svoje ime i prezime /
            ime firme prilikom uplaćivanja
            <br />
            • Obavezno popuniti polje "poziv na broj"
            <br />
            • Svrha uplate: Humanitarni turnir
            <br />
            • Primalac: PHP Srbija, Šeširdžijska 1, 11000 Beograd
            <br />
            • Šifra plaćanja: 187
            <br />
            • Valuta: RSD
            <br />
            • Iznos: xxxxx,xx RSD
            <br />
            • Račun primaoca: 160-383598-90
            <br />• Poziv na broj: humanitarni turnir{' '}
          </p>
        </article>
    </div>
  </Layout>
)

export default DonacijePage
